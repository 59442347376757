// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './components/Home';
import Contact from './components/Contact';
import Services from './components/Services';
import Projects from './components/Projects';
import Misiune from './components/MisiuneValori';
import Legal from './components/Legal';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <Router>
      <div>
        <Navigation />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/misiunevalori" component={Misiune} />
          {/* <Route path="/projects" component={Projects} /> */}
          <Route path="/contact" component={Contact} />
          <Route path="/legal" component={Legal} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}
export default App;
