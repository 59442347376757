// Services.js
import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Carousel } from 'react-bootstrap';
import slide1_m from '../assets/slide1_m.jpg';
import slide2_m from '../assets/slide2_m.jpg';
import slide3_m from '../assets/slide3_m.jpg';
import slide4_m from '../assets/slide4_m.jpg';
import slide5_m from '../assets/slide5_m.jpg';
import slide6_m from '../assets/slide6_m.jpg';

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      title: 'Execuție lucrări :',
      description: '<ol><li>Pregătire terasament</li><li>⁠Aducere la profil acostamente</li><li>⁠Curățare  șanțuri</li><li>⁠Tratare burdușiri și tasări locale</li><li>⁠Reparare suprafețe degradate</li><li>⁠Așternere mixturi asfaltice</li></ol>',
      images: [slide1_m, slide3_m, slide5_m]
    },
    {
      title: 'Închiriere utilaje:',
      description: '<ol><li>Repartizatoare - finisoare asfalt</li><li>Freze asfalt</li>Cilindrii compactori</li><li>Excavatoare</li><li>Buldoexcavatoare</li><li>Trailere</li><li>Autobasculanta 8x4</li><li>Ansamblu cu semiremorca ( transport mixturi asfaltice, balast , nisip , pământ pentru construcții, refuz de ciur, sort, beton concasat/ piatra concasată )</li></ol>',
      images: [slide2_m, slide4_m, slide6_m]
    },
    {
      title: 'Reparare și Reabilitare Drumurig',
      description: 'Realizăm lucrări de reparații și reabilitare a drumurilor existente, asigurându-ne că infrastructura rutieră rămâne funcțională și în condiții optime.',
      images: [slide5_m, slide1_m, slide3_m]
    },
    {
      title: 'Infrastructură Urbană',
      description: 'Participăm la proiecte de dezvoltare urbană, realizând lucrări de infrastructură rutieră în zone urbane, inclusiv trotuare, piste de biciclete și sensuri giratorii.',
      images: [slide2_m, slide4_m, slide6_m]
    },
  ];

  const handleCloseModal = () => setShowModal(false);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setShowModal(true);
  };

  return (
    <section id="services" className="py-5">
      <Container>
        <h1 className="text-center mb-4">Serviciile noastre</h1>
        <Row>
          {services.map((service, index) => (
            <Col key={index} md={6} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text dangerouslySetInnerHTML={{ __html: service.description }} />
                  <Button variant="primary" onClick={() => handleServiceClick(service)}>Mai multe...</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedService && selectedService.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel controls={true} interval={null}>
            {selectedService && selectedService.images.map((image, index) => (
              <Carousel.Item key={index}>
                <img className="d-block w-100" src={image} alt={`Slide ${index}`} />
              </Carousel.Item>
            ))}
          </Carousel>
          <p className="mt-3">{selectedService && selectedService.description}</p>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Services;
