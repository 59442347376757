import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-4">
      <Container>
        <Row>
          <Col md={6}>
            <h5>IVV INVESTMENT CON S.R.L.</h5>
            <p>Sediu: Corbii Mari, Județul Dâmbovița</p>
            <p>Punct de lucru: Bulevardul Unirii , Municipiul Făgăraș , Județul Brașov</p>
          </Col>
          <Col md={6}>
            <h5>Contactează-ne</h5>
            <p>E-mail: contact.ivvinvest@gmail.com</p>
            <p>Telefon: +40764101616</p>
            <p>Manager: +40760078378</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
