import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Legal = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="text-center mb-4">Informații cu caracter legal</h1>
          <p>Stimate cititor,</p>
          <p>
            Societatea noastră, S.C. IVVInvestment Con S.R.L., își propune să furnizeze câteva informații despre grupul nostru prin intermediul acestui website. Ne asigurăm că toate informațiile publicate pe acest site sunt verificate cu atenție înainte de publicare, pentru a garanta că sunt complete, corecte și actualizate. Cu toate acestea, erori pot apărea și, în acest sens, ne cerem scuze în cazul în care datele nu sunt complete, corecte sau actualizate. Vă informăm că ne rezervăm dreptul de a modifica sau șterge orice informație fără notificare prealabilă.
          </p>
          <p>
            Având în vedere caracterul pur informativ al acestui website, nu ne asumăm nicio răspundere pentru eventualele daune rezultate din accesarea informațiilor furnizate pe acest site.
          </p>
          <p>
            De asemenea, vă informăm că toate documentele și materialele utilizate pe acest website sunt protejate de drepturile de autor ale societății noastre, iar utilizarea acestora în scopuri comerciale sau în alte scopuri decât cel informativ este strict interzisă fără permisiunea noastră prealabilă.
          </p>
          <p>
            Link-urile către informații externe sunt oferite doar în scop informativ, iar conținutul acestor website-uri este responsabilitatea exclusivă a proprietarilor lor.
          </p>
          <p>
            Menționăm că, dat fiind caracterul pur informativ al acestui website, nu ne asumăm nicio obligație contractuală sau responsabilitate în ceea ce privește orice angajament sau obligații asumate online.
          </p>
          {/* Add more legal information as needed */}
        </Col>
      </Row>
    </Container>
  );
};

export default Legal;
