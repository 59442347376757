// Projects.js
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import proiect1 from '../assets/proiect1.jpg';
import proiect2 from '../assets/proiect2.jpg';
import proiect3 from '../assets/proiect3.jpg';

function Projects() {
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Proiectele noastre:</h1>
        <Card>
          <Card.Img variant="top" src={proiect1} style={{ width: '50%', height: 'auto', marginLeft: 'auto', marginRight: 'auto'  }} />
          <Card.Body>
            <Card.Title style={{ textAlign: 'center', fontSize: '32px' }}>Proiectul de Modernizare a Drumului Principal în Cernica</Card.Title>
            <Card.Text style={{ width: '50%', margin: '0 auto' }}>
              Această lucrare de construcție reprezintă un efort susținut pentru modernizarea infrastructurii rutiere din Cernica. Drumul principal, vital pentru conectarea localităților din zonă, va fi complet reabilitat și modernizat. Proiectul va implica reasfaltarea drumului, extinderea părții carosabile și instalarea unor sisteme de drenaj eficiente pentru a preveni inundațiile. De asemenea, se vor construi trotuare și piste pentru bicicliști, în conformitate cu cele mai recente standarde de siguranță și accesibilitate. Această modernizare va îmbunătăți semnificativ condițiile de trafic și va spori conectivitatea între comunitățile din zonă.
            </Card.Text>
          </Card.Body>
      </Card>
      <Card>
          <Card.Img variant="top" src={proiect2} style={{ width: '50%', height: 'auto', marginLeft: 'auto', marginRight: 'auto'  }}/>
          <Card.Body>
            <Card.Title style={{ textAlign: 'center', fontSize: '32px' }}>Reconstrucția și Lărgirea Drumului Principal în Hotare</Card.Title>
            <Card.Text style={{ width: '50%', margin: '0 auto' }}>
              Proiectul de reconstrucție și lărgire a drumului principal din Hotare are ca scop îmbunătățirea accesibilității și siguranței rutiere în această localitate. Drumul va fi complet reconstruit pentru a rezista la traficul intens și pentru a permite o circulație mai fluidă a autovehiculelor. În plus, se va lărgi carosabilul pentru a permite o mai bună manevrare a vehiculelor mari și pentru a reduce congestionarea traficului. Proiectul include, de asemenea, amenajarea unor intersecții moderne și instalarea de marcaje rutiere și indicatoare de siguranță. Această lucrare va contribui semnificativ la dezvoltarea infrastructurii rutiere locale și la creșterea confortului și siguranței pentru șoferi și pietoni.
            </Card.Text>
          </Card.Body>
      </Card>
      <Card>
          <Card.Img variant="top" src={proiect3} style={{ width: '50%', height: 'auto', marginLeft: 'auto', marginRight: 'auto'  }}/>
          <Card.Body>
            <Card.Title style={{ textAlign: 'center', fontSize: '32px' }}>Construcția unui Pod Modern peste Râul Jiu în Filiași</Card.Title>
            <Card.Text style={{ width: '50%', margin: '0 auto' }}>
              Construcția acestui pod modern peste Râul Jiu în Filiași reprezintă un proiect strategic pentru îmbunătățirea infrastructurii de transport în zonă. Podul nou va conecta cele două maluri ale râului și va facilita circulația autovehiculelor și a pietonilor. Cu o structură solidă și materiale durabile, podul va rezista la condițiile meteorologice extreme și la traficul intens. Proiectul include, de asemenea, amenajarea drumurilor de acces către pod și instalarea de sisteme de iluminare și semnalizare. Construcția acestui pod va contribui la dezvoltarea economică a regiunii și la îmbunătățirea mobilității locuitorilor din Filiași.
            </Card.Text>
          </Card.Body>
      </Card>
    </div>
  );
}

export default Projects;
