import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name: name,
      email: email,
      message: message
    };
    fetch('http://127.0.0.1:8000/send-email/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if (response.ok) {
        alert('Email sent successfully!');
      } else {
        throw new Error('Email sending failed!');
      }
    })
    .catch(error => {
      console.error('Error:', error);
      alert('Failed to send email. Please try again later.');
    });
  };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <h2>Contact Us</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicName">
              <Form.Label><FontAwesomeIcon icon={faUser} /> Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label><FontAwesomeIcon icon={faEnvelope} /> Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formBasicMessage">
              <Form.Label><FontAwesomeIcon icon={faPenToSquare} />Message</Form.Label>
              <Form.Control as="textarea" rows={4} placeholder="Enter your message" value={message} onChange={(e) => setMessage(e.target.value)} />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
        <Col md={6}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4424.170787428068!2d24.96990671125565!3d45.84190195926265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474ca1790cfe63bf%3A0xd5059aaf4dc5edef!2sCatedrala%20Sf%C3%A2ntul%20Ioan%20Botez%C4%83torul!5e0!3m2!1sro!2sro!4v1715169870661!5m2!1sro!2sro"
              style={{ border: 0, width: '100%', height: '450px' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps"
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;