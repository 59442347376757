import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Misiune = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="text-center mb-4">Misiune și Valori</h1>
            <ul style={{ listStyleType: 'none', fontSize: '22px' }}>
                <li><strong><span style={{ color: 'red' }}>I</span>novație</strong> –  Suntem aici pentru a implementa idei cât mai eficiente și sustenabile pentru dumnevoastră. </li>
                <li><strong><span style={{ color: 'red' }}>V</span>arietate</strong> – Acest cuvânt ne caracterizează ținând cont de portofoliul nostru de lucrări, dar și de multitudinea serviciilor pe care le oferim. </li>
                <li><strong><span style={{ color: 'red' }}>V</span>izibilitate</strong> – Într-un domeniu atât de complex și competitiv reusim să ne evidențiem intr-un mod pozitiv pe piata actuală.</li>
            </ul>
          {/* Add more legal information as needed */}
        </Col>
      </Row>
        <Row>
            <Col> 
                <h1 className="text-center mb-4">Cine suntem ?</h1>
                <p>Suntem o companie 100% romaneasca, al carui obiect principal de activitate este executarea lucrarilor de constructii a drumurilor.
        IVV INVESTMENT CON a reusit prin perseverenta, transparenta, investitii sistematice in tehnologii moderne si seriozitate sa reprezinte un pilon important pe piata constructiilor de drumuri.</p>
            </Col>
            <Col> 
                <h1 className="text-center mb-4">Cu ce ne ocupam?</h1>
                <ul>
                    <li>Execuție lucrări </li>
                    <li>Închiriere utilaje </li>
                    <li>Deszăpezire</li>
                </ul>
            </Col>
        </Row>
    </Container>
  );
};

export default Misiune;
