// Home.js
import React, {useState} from 'react';
import Card from 'react-bootstrap/Card';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import slide1_m from '../assets/slide1_m.jpg';
import slide2_m from '../assets/slide2_m.jpg';
import slide3_m from '../assets/slide3_m.jpg';
import slide4_m from '../assets/slide4_m.jpg';
import slide5_m from '../assets/slide5_m.jpg';
import slide6_m from '../assets/slide6_m.jpg';
import slide1_s from '../assets/slide1_s.jpg';
import slide2_s from '../assets/slide2_s.jpg';
import slide3_s from '../assets/slide3_s.jpg';
import slide4_s from '../assets/slide4_s.jpg';
import slide5_s from '../assets/slide5_s.jpg';
import slide6_s from '../assets/slide6_s.jpg';
import beneficiul1 from '../assets/beneficiul1_m.jpg';
import beneficiul2 from '../assets/beneficiul2_m.jpg';
import beneficiul3 from '../assets/beneficiul3_m.jpg';
import '../styles/Home.css'

function Home() {    
    const [activeBenefit, setActiveBenefit] = useState(1); // State to track active benefit

    const handleBenefitClick = (benefitNumber) => {
        setActiveBenefit(benefitNumber);
    };
    return (
        <div>
            <div>
                <Carousel interval={2000}>  
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={slide1_m}
                        srcSet={`${slide1_s} 640w, ${slide1_m} 1024w`}
                        sizes="(max-width: 640px) 640px, (max-width: 1024px) 1024px, 1600px"
                        alt="Slide 1"
                        style={{
                          height: '90vh',
                          objectFit: 'cover'
                        }}
                        />
                        <Carousel.Caption className='carousel-caption'>
                            <p>Serviciile noastre fac parte din industria construcțiilor de drumuri.
                                Aducem oamenii, materialele de construcții și utilajele în momentul potrivit la locul potrivit pentru a realiza chiar și cele mai complexe proiecte de construcții
                                Datorită experienței acumulate în timp  acceptăm orice provocare privind modernizarea, extinderea și construirea infrastructurii de transport rutier, oferind transparenta si seriozitate.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 carousel-image"
                        src={slide2_m}
                        srcSet={`${slide2_s} 640w, ${slide2_m} 1024w`}
                        sizes="(max-width: 640px) 640px, (max-width: 1024px) 1024px, 1600px"
                        alt="Slide 2"
                        style={{
                          height: '90vh',
                          objectFit: 'cover'
                        }}
                        />
                        <Carousel.Caption className='carousel-caption'>
                            <p>Serviciile noastre fac parte din industria construcțiilor de drumuri.
                                Aducem oamenii, materialele de construcții și utilajele în momentul potrivit la locul potrivit pentru a realiza chiar și cele mai complexe proiecte de construcții
                                Datorită experienței acumulate în timp  acceptăm orice provocare privind modernizarea, extinderea și construirea infrastructurii de transport rutier, oferind transparenta si seriozitate.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 carousel-image"
                        src={slide3_m}
                        srcSet={`${slide3_s} 640w, ${slide3_m} 1024w`}
                        sizes="(max-width: 640px) 640px, (max-width: 1024px) 1024px, 1600px"
                        alt="Slide 3"
                        style={{
                          height: '90vh',
                          objectFit: 'cover'
                        }}
                        />
                        <Carousel.Caption className='carousel-caption'>
                            <p>Serviciile noastre fac parte din industria construcțiilor de drumuri.
                                Aducem oamenii, materialele de construcții și utilajele în momentul potrivit la locul potrivit pentru a realiza chiar și cele mai complexe proiecte de construcții
                                Datorită experienței acumulate în timp  acceptăm orice provocare privind modernizarea, extinderea și construirea infrastructurii de transport rutier, oferind transparenta si seriozitate.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 carousel-image"
                        src={slide4_m}
                        srcSet={`${slide4_s} 640w, ${slide4_m} 1024w`}
                        sizes="(max-width: 640px) 640px, (max-width: 1024px) 1024px, 1600px"
                        alt="Slide 4"
                        style={{
                          height: '90vh',
                          objectFit: 'cover'
                        }}
                        />
                        <Carousel.Caption className='carousel-caption'>
                            <p>Serviciile noastre fac parte din industria construcțiilor de drumuri.
                                Aducem oamenii, materialele de construcții și utilajele în momentul potrivit la locul potrivit pentru a realiza chiar și cele mai complexe proiecte de construcții
                                Datorită experienței acumulate în timp  acceptăm orice provocare privind modernizarea, extinderea și construirea infrastructurii de transport rutier, oferind transparenta si seriozitate.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 carousel-image"
                        src={slide5_m}
                        srcSet={`${slide5_s} 640w, ${slide5_m} 1024w`}
                        sizes="(max-width: 640px) 640px, (max-width: 1024px) 1024px, 1600px"
                        alt="Slide 5"
                        style={{
                          height: '90vh',
                          objectFit: 'cover'
                        }}
                        />
                        <Carousel.Caption className='carousel-caption'>
                            <p>Serviciile noastre fac parte din industria construcțiilor de drumuri.
                                Aducem oamenii, materialele de construcții și utilajele în momentul potrivit la locul potrivit pentru a realiza chiar și cele mai complexe proiecte de construcții
                                Datorită experienței acumulate în timp  acceptăm orice provocare privind modernizarea, extinderea și construirea infrastructurii de transport rutier, oferind transparenta si seriozitate.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100 carousel-image"
                        src={slide6_m}
                        srcSet={`${slide6_s} 640w, ${slide6_m} 1024w`}
                        sizes="(max-width: 640px) 640px, (max-width: 1024px) 1024px, 1600px"
                        alt="Slide 6"
                        style={{
                          height: '90vh',
                          objectFit: 'cover'
                        }}
                        />
                        <Carousel.Caption className='carousel-caption'>
                            <p>Serviciile noastre fac parte din industria construcțiilor de drumuri.
                                Aducem oamenii, materialele de construcții și utilajele în momentul potrivit la locul potrivit pentru a realiza chiar și cele mai complexe proiecte de construcții
                                Datorită experienței acumulate în timp  acceptăm orice provocare privind modernizarea, extinderea și construirea infrastructurii de transport rutier, oferind transparenta si seriozitate.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div style={{padding: '20px'}}>
                <Container className="mt-5">
                    <Row>
                        <Col>
                        <h1 className="text-center mb-4">Misiune și Valori</h1>
                            <ul style={{ listStyleType: 'none', fontSize: '22px' }}>
                                <li><strong><span style={{ color: 'red' }}>I</span>novație</strong> –  Suntem aici pentru a implementa idei cât mai eficiente și sustenabile pentru dumnevoastră. </li>
                                <li><strong><span style={{ color: 'red' }}>V</span>arietate</strong> – Acest cuvânt ne caracterizează ținând cont de portofoliul nostru de lucrări, dar și de multitudinea serviciilor pe care le oferim. </li>
                                <li><strong><span style={{ color: 'red' }}>V</span>izibilitate</strong> – Într-un domeniu atât de complex și competitiv reusim să ne evidențiem intr-un mod pozitiv pe piata actuală.</li>
                            </ul>
                        {/* Add more legal information as needed */}
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-5">
                    <Row>
                        <Col> 
                            <h1 className="text-center mb-4">Cine suntem ?</h1>
                            <p>Suntem o companie 100% romaneasca, al carui obiect principal de activitate este executarea lucrarilor de constructii a drumurilor.
IVV INVESTMENT CON a reusit prin perseverenta, transparenta, investitii sistematice in tehnologii moderne si seriozitate sa reprezinte un pilon important pe piata constructiilor de drumuri.</p>
                        </Col>
                        <Col> 
                            <h1 className="text-center mb-4">Cu ce ne ocupam?</h1>
                            <ul>
                                <li>Execuție lucrări </li>
                                <li>Închiriere utilaje </li>
                                <li>Deszăpezire</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style = {{paddingTop: "20px", paddingBottom: "20px", backgroundColor:"#1111"}}>
                <Container>
                    <Row>
                        <Col md="3">
                            <ul className="list-group">
                                <li className={activeBenefit === 1 ? "list-group-item active" : "list-group-item"} onClick={() => handleBenefitClick(1)}>Profesionalism</li>
                                <li className={activeBenefit === 2 ? "list-group-item active" : "list-group-item"} onClick={() => handleBenefitClick(2)}>Promptitudine</li>
                                <li className={activeBenefit === 3 ? "list-group-item active" : "list-group-item"} onClick={() => handleBenefitClick(3)}>Calitate/Pret</li>
                            </ul>
                        </Col>
                        <Col>
                            <Carousel indicators={false} controls={false} fade interval={null} activeIndex={activeBenefit - 1} className="h-100">
                                <Carousel.Item>
                                    <img 
                                        className="d-block w-100"
                                        src={beneficiul1} 
                                        alt="First slide"
                                        style={{
                                          height: '60vh',
                                          objectFit: 'cover'
                                        }}
                                    />
                                    <Carousel.Caption className='carousel-caption'>
                                        <h3>Competență Profesională</h3>
                                        <p>Explorează profunzimea cunoștințelor noastre și abilitatea noastră de a oferi soluții eficiente și inovatoare în domeniul nostru de activitate. Cu o experiență vastă și o înțelegere profundă a industriei, ne străduim să fim lideri în furnizarea unor servicii de înaltă calitate, adaptate nevoilor tale specifice.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img 
                                        className="d-block w-100"
                                        src={beneficiul2} 
                                        alt="Second slide"
                                        style={{
                                          height: '60vh',
                                          objectFit: 'cover'
                                        }}
                                    />
                                    <Carousel.Caption className='carousel-caption'>
                                        <h3>Eficiență</h3>
                                        <p>Descoperă angajamentul nostru față de livrarea rapidă și eficientă a serviciilor noastre. Ne angajăm să răspundem prompt la solicitările tale, să respectăm termenele stabilite și să oferim soluții în timp util, pentru a-ți asigura o experiență plină de eficiență și satisfacție.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img 
                                        className="d-block w-100"
                                        src={beneficiul3} 
                                        alt="Third slide"
                                        style={{
                                          height: '60vh',
                                          objectFit: 'cover'
                                        }}
                                    />
                                    <Carousel.Caption className='carousel-caption'>
                                        <h3>Raport Calitate-Pret</h3>
                                        <p>Descoperă angajamentul nostru față de echilibrul perfect între calitatea serviciilor noastre și valoarea pe care o oferim clienților noștri. Ne străduim să oferim produse și servicii de cea mai înaltă calitate la prețuri competitive, astfel încât să te bucuri de o experiență satisfăcătoare și cu un raport excelent între calitate și cost.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>
            <h1 style={{textAlign: 'center'}}>Sa ne cunoastem:</h1>
            <div className="d-flex justify-content-around" style={{paddingBottom: '20px', textAlign:'center'}}>
                <Card style={{ width: '36rem' }}>
                    {/* <Card.Img variant="top" src={pers2} /> */}
                    <Card.Body>
                    <Card.Title style={{fontWeight: 'lighter', fontSize: '26px'}}>Nume 1</Card.Title>
                    <Card.Text>
                        <h5>Pozitia detinuta in companie</h5>
                        <p>scurta descriere</p>
                    </Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{ width: '36rem' }}>
                    {/* <Card.Img variant="top" src={pers3} /> */}
                    <Card.Body>
                    <Card.Title style={{fontWeight: 'lighter', fontSize: '26px'}}>Nume 2</Card.Title>
                    <Card.Text>
                        <h5>Pozitia detinuta in companie</h5>
                        <p>scurta descriere</p>
                    </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default Home;
